import React from "react";
import ContactesBanner from "./ContactBanner/ContactBanner";

function Contact() {
  return (
    <div>
      <ContactesBanner />
    </div>
  );
}

export default Contact;
