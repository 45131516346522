import Carousel from "react-bootstrap/Carousel";
import { Link } from 'react-router-dom';
import solarPanelsIcon from "../../../assets/img/solar-panelsicon.png";
import CheckBestDeals from "../../../assets/img/CheckBestDeals.png";
import solarInverters from "../../../assets/img/solar-Inverters.png";
import batteryStorage from "../../../assets/img/Solar-battery-storage.png";
import { useNavigate } from 'react-router-dom';
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./Slider.css";


function Slider() {

  const navigate = useNavigate();
  
const redirectToProduct =()=>{

  navigate('/products')
};
 

  return (
    <div className="bnner-slider">
      <Carousel interval={null}>
        <Carousel.Item className="home-bnner item1">
          <Carousel.Caption className=" slideLeft">
            <Row>
              <Col
                className="slideLeftt "
                lg={12}
                md={12}
                xl={12}
                sm={12}
                xs={12}
              >
                <p>SOLAR PANEL INSTALLATION IN MELBOURNE</p>
                <h1>
                  Powering Homes With<br></br> Solar Panels
                </h1>
                <Button variant="danger" className="my-bttn" onClick={redirectToProduct}>
                  Discover More
                </Button>
              </Col>
              <Col className="slideRight " lg={6} md={6} xl={6} sm={12} xs={12}>
                <div className=" for-mob">
                  <div className="energy-text ">
                    <div className="slider-lower-box text-center">
                    <Link to="/products#ourplane">
                      <img src={CheckBestDeals} alt="Redefining Solar"  />
                      <h5>Check Solar Deals</h5>
                      </Link>
                    </div>
                    <div className="slider-lower-box text-center">
                    <Link to="/products#ourplane">
                      <img src={solarPanelsIcon} alt="Redefining Solar" />
                      <h5>Solar Panels</h5>
                      </Link>
                    </div>
                    <div className="slider-lower-box text-center">
                    <Link to="/products#ourplane">
                      <img src={solarInverters} alt="Redefining Solar" />
                      <h5>Solar Inverters</h5>
                      </Link>
                    </div>
                    <div className="slider-lower-box text-center">
                    <Link to="/products#ourplane">
                      <img src={batteryStorage} alt="Redefining Solar" />
                      <h5>Solar Battery Storage</h5>
                      </Link>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item className="home-bnner item2">
          <Carousel.Caption className="  slideLeft">
            <Row>
              <Col
                className="slideLeftt "
                lg={12}
                md={12}
                xl={12}
                sm={12}
                xs={12}
              >
                <p>SOLAR PANEL INSTALLATION IN MELBOURNE</p>
                <h1>
                  Powering Homes With<br></br> Solar Panels
                </h1>
                <Button variant="danger" className="my-bttn">
                  Discover More
                </Button>
              </Col>
              <Col className="slideRight" lg={6} md={6} xl={6} sm={12} xs={12}>
                <div className=" for-mob">
                  <div className="energy-text ">
                    <div className="slider-lower-box text-center">
                      <img src={CheckBestDeals} alt="Redefining Solar" />
                      <h5>Check Solar Deals</h5>
                    </div>
                    <div className="slider-lower-box text-center">
                      <img src={solarPanelsIcon} alt="Redefining Solar" />
                      <h5>Solar Panels</h5>
                    </div>
                    <div className="slider-lower-box text-center">
                      <img src={solarInverters} alt="Redefining Solar" />
                      <h5>Solar Inverters</h5>
                    </div>
                    <div className="slider-lower-box text-center">
                      <img src={batteryStorage} alt="Redefining Solar" />
                      <h5>Solar Battery Storage</h5>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item className="home-bnner item3">
          <Carousel.Caption className=" slideLeft">
            <Row>
              <Col
                className="slideLeftt"
                lg={12}
                md={12}
                xl={12}
                sm={12}
                xs={12}
              >
                <p>SOLAR PANEL INSTALLATION IN MELBOURNE</p>
                <h1>
                  Powering Homes With<br></br> Solar Panels
                </h1>
                <Button variant="danger" className="my-bttn">
                  Discover More
                </Button>
              </Col>
              <Col className="slideRight" lg={6} md={6} xl={6} sm={12} xs={12}>
                <div className=" for-mob">
                  <div className="energy-text ">
                    <a >
                    <div className="slider-lower-box text-center">
                      <img src={CheckBestDeals} alt="Redefining Solar" />
                      <h5>Check solar Deals</h5>
                    </div></a>
                    <div className="slider-lower-box text-center">
                      <img src={solarPanelsIcon} alt="Redefining Solar" />
                      <h5>Solar Panels</h5>
                    </div>
                    <div className="slider-lower-box text-center">
                      <img src={solarInverters} alt="Redefining Solar" />
                      <h5>Solar Inverters</h5>
                    </div>
                    <div className="slider-lower-box text-center">
                      <img src={batteryStorage} alt="Redefining Solar" />
                      <h5>Solar Battery Storage</h5>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
      <div className="energy-text for-deskstop">
      
        <div className="slider-lower-box text-center">
        <Link to="/products#ourplane">
          <img src={CheckBestDeals} alt="Redefining Solar"/>
          <h5>Check Solar Deals</h5>
          </Link>
        </div>
        <div className="slider-lower-box text-center">
          <Link to="/products#solardeals">
            <img src={solarPanelsIcon} alt="Redefining Solar"/>
            <h5>Solar Panels</h5>
          </Link>
        </div>
        <div className="slider-lower-box text-center">
        <Link to="/products#solardeals">
          <img src={solarInverters} alt="Redefining Solar" />
          <h5>Solar Inverters</h5>
          </Link>
        </div>
        <div className="slider-lower-box text-center">
        <Link to="/products#solardeals">
          <img src={batteryStorage} alt="Redefining Solar" />
          <h5>Solar Battery Storage</h5>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Slider;
