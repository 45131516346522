import React from "react";
import Container from "react-bootstrap/Container";
import "./Installed.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import callingServices from "../../../assets/img/callingServices.png";
import touch from "../../../assets/img/touch.png";
import thums from "../../../assets/img/thums.png";
import enjoy from "../../../assets/img/enjoy.png";

function Installed() {
  return (
    <div>
      <div className="Installed hero ">
        <Container>
          <Row>
            <Col>
              <div className="Installed-heading text-center">
                <h2 className="line">
                  4 easy steps to getting your solar panels <br></br> installed
                </h2>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={3} md={6} xl={3} sm={12} xs={12}>
              <div className="Installed-box1">
                <div className="Installed-box-img">
                  <img
                    className="d-block w-100"
                    src={touch}
                    alt="Redefining Solar"
                  />
                </div>
                <div className="Installed-box-containent text-center">
                  <h5>Get in touch</h5>
                  <p>
                    Our solar team is ready and waiting with a host of
                    technology tools and expertise to give you the best solar
                    yield and returns.
                  </p>
                </div>
              </div>
            </Col>
            <Col lg={3} md={6} xl={3} sm={12} xs={12}>
              <div className="Installed-box2">
                <div className="Installed-box-img">
                  <img
                    className="d-block w-100"
                    src={callingServices}
                    alt="Redefining Solar"
                  />
                </div>
                <div className="Installed-box-containent text-center">
                  <h5>We take care of everything</h5>
                  <p>
                    We'll take care of the whole process, from helping with
                    government rebates, to designing the system perfect for your
                    home.
                  </p>
                </div>
              </div>
            </Col>
            <Col lg={3} md={6} xl={3} sm={12} xs={12}>
              <div className="Installed-box3">
                <div className="Installed-box-img">
                  <img
                    className="d-block w-100"
                    src={thums}
                    alt="Redefining Solar"
                  />
                </div>
                <div className="Installed-box-containent text-center ">
                  <h5> Get Installed</h5>
                  <p>
                    Our fully accredited and licensed electricians take great
                    care with your solar panels installation to ensure your
                    system is correctly installed and compliant with Government
                    standards.
                  </p>
                </div>
              </div>
            </Col>
            <Col lg={3} md={6} xl={3} sm={12} xs={12}>
              <div className="Installed-box4">
                <div className="Installed-box-img">
                  <img
                    className="d-block w-100"
                    src={enjoy}
                    alt="Redefining Solar"
                  />
                </div>
                <div className="Installed-box-containent text-center">
                  <h5> Enjoy your free power</h5>
                  <p>
                    Sit back and relax as your system will now be fully
                    functional. Your solar energy system is in good hands with
                    our dedicated after sales support.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default Installed;
