import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";

import Button from "react-bootstrap/Button";
import "./ServicesBanner.css";
function ServicesBanner() {
  return (
    <div>
      <div className="ServicesBanner">
        <Container>
          <Row>
            <Col lg={12} md={12} xl={12} sm={12} xs={12}>
              <div className="ServicesBanner-left">
                <h2>Services</h2>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="ServicesBannerAppsulate">
        <Container>
          <div className="ServicesBannerAppsulateText">
            <h3>A simple, affordable way to control your energy bill </h3>
            <Button variant="danger" className="my-bttn">
              Get a Quote
            </Button>
          </div>
        </Container>
      </div>
    </div>
  );
}

export default ServicesBanner;
