import React from "react";
import "./OurPlane.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import { ReactDOM } from "react-dom/client";
import img1 from "../../../assets/img/zinko.jpeg";
import img2 from "../../../assets/img/innverter.jpeg";
import img3 from "../../../assets/img/Risen.png";
import img4 from "../../../assets/img/sofar.png";
function OurPlane() {
  const navigate = useNavigate();
  const formEnquiry = () => {
    navigate("/GetQuote");
  };
  return (
    <div className="OurPlane">
      <Container>
        <Row>
          <Col className="text-center">
            <h2 className="line">Our Plan</h2>
            <p>
              ALL PANLES ABOVE HAVE 25 YEARS PRODUCT WARRANTY <br></br>ALL
              INVERTERS HAVE 10 YEARS PRODUCT WARRANTY
            </p>
          </Col>
        </Row>

        <div className="OurPlane-content">
          <div className="OurPlane-content-box">
            <h5 className="line">Premium System</h5>
            <div className="OurPlane-content-imgbox">
              <div className="OurPlane-imgbox">
                <img src={img1} alt="Redefining Solar" />
              </div>
              <div className="OurPlane-imgbox">
                <img src={img2} alt="Redefining Solar" />
              </div>
            </div>
            <ul>
              <li>6.6kw solar system</li>
              <li>
                16 JINKO N TYPE 415 watts Solar panels with 5kw Sungrow single
                phase Inverter
              </li>
              <li>ADD $650 for FRONIUS 5Kw inverter</li>
              <li>
                $3290* after the Solar Vic Rebate($1400) and Solar VIC Interest
                free Loan($29.17 per month repayment for 48 months
              </li>
            </ul>
            <Button onClick={formEnquiry} variant="danger" className="my-bttn ">
              Get a Quote
            </Button>
          </div>
          <div className="OurPlane-content-box">
            <h5 className="line">Middle Segment system</h5>
            <div className="OurPlane-content-imgbox">
              <div className="OurPlane-imgbox">
                <img src={img3} alt="Redefining Solar" />
              </div>
            </div>
            <ul>
              <li>
                16 RISEN 415 watts Solar panels with 5kw GOODWE single phase
                Inverter
              </li>
              <li>
                $2690 *after the Solar Vic Rebate($1400) and Solar VIC Interest
                free Loan($29.17 per month repayment for 48 months.
              </li>
            </ul>
            <Button onClick={formEnquiry} variant="danger" className="my-bttn ">
              Get a Quote
            </Button>
          </div>
          <div className="OurPlane-content-box">
            <h5 className="line">Starting segment: 6.3kw</h5>
            <div className="OurPlane-content-imgbox">
              <div className="OurPlane-imgbox">
                <img src={img4} alt="Redefining Solar" />
              </div>
            </div>
            <ul>
              <li>
                16 Astronergy 450 watts Solar panels with 5kw SOFAR single phase
                Inverter
              </li>
              <li>
                $2190 *after the Solar Vic Rebate($1400) and Solar VIC Interest
                free Loan($29.17 per month repayment for 48 months.
              </li>
            </ul>
            <Button onClick={formEnquiry} variant="danger" className="my-bttn ">
              Get a Quote
            </Button>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default OurPlane;
