import React from "react";
import $ from "jquery";
import "./ApsulteHeader.css";
import { useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons";

function ApsulteHeader() {
  $(window).scroll(function () {
    if ($(this).scrollTop() > 320) {
      $(".apsulteheader").addClass("sticky");
    } else {
      $(".apsulteheader").removeClass("sticky");
    }
  });

  const navigate = useNavigate();
  const formEnquiry = () => {
    navigate("/contact");
  };

  return (
    <div className="apsulteheader">
      <Container>
        <div className="apsulteheader-inner">
          <div className="apsulteheader-inner-left">
            <a href="mailto:redefiningsolar@gmail.com">
              <span>
                <FontAwesomeIcon icon={faEnvelope}></FontAwesomeIcon>
              </span>
              Send email
            </a>
          </div>
          <div className="apsulteheader-inner-right">
            <a href="tel:+61-425 400 755">
              <span>
                <FontAwesomeIcon icon={faPhone}></FontAwesomeIcon>
              </span>
              1300309956
            </a>

            <Button onClick={formEnquiry} variant="danger" className="my-bttn2">
              Enquiry Now
            </Button>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default ApsulteHeader;
