import React from "react";

import Slider from "./slider/Slider";
import Introductiomn from "./Introductiomn/Introductiomn";
import Installed from "./Installed/Installed";
import Products from "./Products/Products";
import OurPlane from "./OurPlane/OurPlane";

function Home() {
  return (
    <div>
      <Slider></Slider>
      <Products></Products>
      <OurPlane />
      <Introductiomn></Introductiomn>
      <div className="panelsinstalled">
        <Installed></Installed>
      </div>
    </div>
  );
}

export default Home;
