import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import "./AboutContent.css";
import aboutcontent from "../../../assets/img/Aboutcontent.jpg";
function AboutContent() {
  return (
    <div className="AboutContent">
      <Container>
        <Row>
          <Col lg={6} md={6} xl={6} sm={12} xs={12}>
            <div className="AboutContent-left">
              <h2>
                About <br></br> <span className="green">Redefining </span>
                <span className="Blue">solar</span>
              </h2>
              <h5>
                Solar panel Installation in Melbourne, New South Wales &
                Queensland.
              </h5>
              <p>
                Redefining Solar is an Australian owned and operated company, we
                provide professional customer support and an affordable way to
                power their homes with the sun and take back control of their
                energy. From highly trained assessors to professional customer
                service representatives, we aim to exceed expectations from
                initial assessment to aftercare for our products. Customers can
                find inner peace while dealing with us. Redefine Solar are all
                for helping our customers .
              </p>
              <p>
                By continuing to challenge the conventions of traditional energy
                technologies, Redefining Solar has become a global leader in
                energy efficiency and renewable energy solutions for commercial
                buildings. We offer services and products that promote reduced
                carbon emissions and healthy buildings to support your
                sustainability goals.
              </p>
              <p>
                Currently We provide services in Melbourne, New South Wales &
                Queensland.
              </p>
            </div>
          </Col>
          <Col lg={6} md={6} xl={6} sm={12} xs={12}>
            <div className="AboutContent-Right">
              <img src={aboutcontent} alt="Redefining Solar" />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default AboutContent;
