import React from "react";
import PrivacyBanner from "./PrivacyBanner/PrivacyBanner";

function Privacy() {
  return (
    <div>
      <PrivacyBanner />
    </div>
  );
}

export default Privacy;
