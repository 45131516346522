import { React } from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import logo from "../../assets/img/logo.png";
//import Call from "../../assets/img/phone-call.png";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import "./Header.css";

function Header() {
  const navigate = useNavigate();
  const formEnquiry = () => {
    navigate("/GetQuote");
  };

  return (
    <div>
      <Navbar expand="lg">
        <Container>
          <Navbar.Brand>
            <Nav.Link as={Link} to={"/home"}>
              <img src={logo} className="App-logo" alt="Redefining Solar" />
            </Nav.Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link as={Link} to={"/home"} className="hvr-float">
                Home
              </Nav.Link>
              <Nav.Link as={Link} to={"/about"} className="hvr-float">
                About
              </Nav.Link>
              <Nav.Link as={Link} to={"/products"} className="hvr-float">
                Products
              </Nav.Link>

              {/*<Nav.Link as={Link} to={"/services"} className="hvr-float">
                Services
  </Nav.Link>*/}
              <Nav.Link as={Link} to={"/contact"} className="hvr-float">
                Contact Us
              </Nav.Link>
            </Nav>
            <div className="header-button  ">
              <Button
                onClick={formEnquiry}
                variant="danger"
                className="my-bttn"
              >
                Get a Quote
              </Button>
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}

export default Header;
