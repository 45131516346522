import React from "react";

import AboutBanner from "./AboutBanner/AboutBanner";
import AboutContent from "./AboutContent/AboutContent";
import Installed from "./../Home/Installed/Installed";

function About() {
  return (
    <div>
      <AboutBanner></AboutBanner>
      <AboutContent />
      <div className="about-installed">
        <Installed />
      </div>
    </div>
  );
}

export default About;
