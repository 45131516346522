import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React from "react";
import "./Introductiomn.css";
import Imgleft from "../../../assets/img/first.jpg.jpg";
import Imgcenter from "../../../assets/img/solarWater.jpg";
import Imglst from "../../../assets/img/Solar-Quotes.jpg";
export default function Introductiomn() {
  return (
    <div className=" Introductiomn">
      <Container>
        <Row>
          <Col className="text-center mt-4 Introductiomn-heading">
            <h2 className="line">GET STARTED HERE</h2>
            <p>
              Looking for solar panels, solar hot water or service and repairs?
              Choose from our most popular services below.
            </p>
          </Col>
        </Row>
        <Row className="text-center mt-4 Introductiomn-box">
          <Col lg={4} md={4} xl={4} sm={12} xs={12}>
            <img src={Imgleft} alt="Redefining Solar" />
            <div className="img-content-box text-center">
              <h3 className="line">Solar Power</h3>
              <p>Enjoy the limitless power of solar technology at home</p>
            </div>
          </Col>
          <Col lg={4} md={4} xl={4} sm={12} xs={12}>
            <img src={Imgcenter} alt="Redefining Solar" />
            <div className="img-content-box text-center">
              <h3 className="line">Solar Hot Water</h3>
              <p>
                Start saving more than 20% on your electricity <br></br>bills.
              </p>
            </div>
          </Col>
          <Col lg={4} md={4} xl={4} sm={12} xs={12}>
            <img src={Imglst} alt="Redefining Solar" />
            <div className="img-content-box text-center">
              <h3 className="line">Solar Rebates & Subsidies </h3>
              <p>
                Are you considering solar installation and are living in
                Victoria.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
