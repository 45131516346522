import React, { useEffect } from 'react';
import ProjectBanner from "./ProjectsBanner/ProjectsBanner";
import Products from "./../Home/Products/Products";
import OurPlane from "./../Home/OurPlane/OurPlane";



function Projects() {

    useEffect(() => {
      const hash = window.location.hash.substr(1); // get the URL hash without the "#" character
      const targetElement = document.getElementById(hash); // get the element with the corresponding ID
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: 'smooth' }); // scroll to the element
      }
    }, []);

  return (
    <div>
      <ProjectBanner />
      <div id="solardeals">
      <Products />
      </div>
      <div id="ourplane">
        <OurPlane/>
      </div>
    </div>
  );
}

export default Projects;
