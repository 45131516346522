import React from "react";
import GetForm from "./GetForm/GetForm";

function GetQuote() {
  return (
    <div className="GetForm">
      <GetForm />
    </div>
  );
}

export default GetQuote;
