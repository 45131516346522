import { React } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
//import { createRoot } from "react-dom/client";
import ReactGA from "react-ga4";

import "./App.css";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";

import Home from "./components/Home/Home";
import ApsulteHeader from "./components/apsulteHeader/ApsulteHeader";
import About from "./components/About/About";
import Projects from "./components/Projects/Projects";
import Services from "./components/Services/Services";
import Contact from "./components/Contact/Contact";
import ScrollTop from "./components/ScrollTop/ScrollTop";
import GetQuote from "./components/GetQuote/GetQuote";
import Privacy from "./components/Privacy/Privacy";

ReactGA.initialize(" G-2QCVN8CPBN");

ReactGA.initialize("your GA measurement id");
ReactGA.send("pageview");

function App() {
  return (
    <BrowserRouter basename="/">
      <ApsulteHeader />
      <Header />
      <ScrollTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/products" element={<Projects />} />
        <Route path="/services" element={<Services />} />
        <Route path="/Contact" element={<Contact />} />
        <Route path="/GetQuote" element={<GetQuote />} />
        <Route path="/privacy" element={<Privacy />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
