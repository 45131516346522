import { React, useRef, useState } from "react";
// import emailjs from "@emailjs/browser";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";

import { Modal } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import "./GetForm.css";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";

function GetForm() {
  const form = useRef();
  const [show, setShow] = useState(false);
  const [loader, setLoader] = useState(false);
  const [email, setEmail] = useState("");
  const [firstName, setFirstname] = useState("");
  const [lastName, setLastname] = useState("");

  const [message, setMessage] = useState("");
  const [mobileNumber, setMobilenumber] = useState("");
  const [validated, setValidated] = useState(false);

  const handleClose = () => {
    setShow(false);
    window.location.reload();
  };
  const handleShow = () => setShow(true);
  const handleInputMobilenumber = (event) => {
    let value = event.target.value;
    if (isNaN(value) || value.length > 10) {
      event.target.value = mobileNumber;
    } else {
      setMobilenumber(event.target.value);
    }
  };

  const handleInputEmail = (event) => {
    setEmail(event.target.value);
  };
  const handleInputfirstName = (event) => {
    setFirstname(event.target.value);
  };

  const handleInputlastName = (event) => {
    setLastname(event.target.value);
  };

  const handleInputMessage = (event) => {
    setMessage(event.target.value);
  };

  const sendEmail = async (e) => {
    e.preventDefault();
    console.log({ e });

    if (loader === true) {
      return;
    }
    setLoader(true);

    const requestData = {
      firstname: e.target.firstName.value,
      lastname: e.target.lastName.value,
      email: e.target.email.value,
      message: e.target.message.value,
      subject: "Website Contact",
      contact: e.target.number.value,
      location: e.target.location.value,
    };

    console.log({ requestData });
    const formData = new FormData();

    for (var key in requestData) {
      formData.append(key, requestData[key]);
    }
    console.log("formData", formData);

    try {
      const response = await axios.post(
        "https://redefiningsolar.com.au/send_email.php",
        formData
      );
      setLoader(false);
      setShow(true);
      setValidated(true);
      const responseText = await response.text();
      console.log(responseText);
    } catch (ex) {
      setLoader(false);
      console.log("POST error!");
    }
  };

  return (
    <div>
      <div className="ContactForm">
        <Container>
          <Form ref={form} onSubmit={sendEmail}>
            <Form.Group className="mb-3">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="First Name"
                name="firstName"
                value={firstName}
                onChange={handleInputfirstName}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Last Name"
                name="lastName"
                value={lastName}
                onChange={handleInputlastName}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Location</Form.Label>
              <Form.Control
                type="text"
                placeholder="Location"
                name="location"
                required
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Contact Number</Form.Label>
              <Form.Control
                type="text"
                placeholder="Contact Number"
                name="number"
                value={mobileNumber}
                onChange={handleInputMobilenumber}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                name="email"
                value={email}
                onChange={handleInputEmail}
                required
                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
              />
              <Form.Text className="text-muted">
                We'll never share your email with anyone else.
              </Form.Text>
            </Form.Group>

            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Message</Form.Label>
              <Form.Control as="textarea" rows={3} name="message" />
            </Form.Group>

            <Button
              variant="primary"
              type="submit"
              className="my-bttn"
              disabled={loader}
            >
              Submit
              {loader && (
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              )}
            </Button>
          </Form>

          <div className="googleMap">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3139.412592669824!2d145.19122041526126!3d-38.1073345797003!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad60c4528900f51%3A0xa8f14fd0c95a551e!2s32%20Herrington%20Ave%2C%20Carrum%20Downs%20VIC%203201%2C%20Australia!5e0!3m2!1sen!2sin!4v1672902396361!5m2!1sen!2sin"
              width="100%"
              height="450"
              title="map"
            ></iframe>
          </div>
        </Container>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Form Submitted</Modal.Title>
          </Modal.Header>
          <Modal.Body>Form submitted successfully!</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}

export default GetForm;
