import { React, useRef, useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import { Modal } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import "./ContactBanner.css";
import axios from "axios";

import FormData from "../../../../node_modules/axios/lib/env/classes/FormData";


function ContactBanner() {
  const form = useRef();
  const [show, setShow] = useState(false);
  const [loader, setLoader] = useState(false);
  const [email, setEmail] = useState("");
  const [firstName, setFirstname] = useState("");
  const [lastName, setLastname] = useState("");
  const [mobileNumber, setMobilenumber] = useState("");
  const [message, setMessage] = useState("");
  const [validated, setValidated] = useState(false);

  console.log("email", email);
  console.log("firstName", firstName);
  console.log("lastName", lastName);
  console.log("lastName", lastName);
  console.log("mobileNumber", mobileNumber);
  console.log("message", message);

  const handleClose = () => {
    setShow(false);
    window.location.reload();
  };

  const handleShow = () => setShow(true);

  // const sendEmail = (e) => {
  //   e.preventDefault();
  //   emailjs
  //     .sendForm(
  //       "service_tmvhkt",
  //       "template_xa47k4",
  //       form.current,
  //       "_aN4cQ7bMFQcibHYo"
  //     )
  //     .then(
  //       (result) => {
  //         console.log(result.text);
  //       },
  //       (error) => {
  //         console.log(error.text);
  //       }
  //     );
  // };

  const handleInputEmail = (event) => {
    setEmail(event.target.value);
  };
  const handleInputfirstName = (event) => {
    setFirstname(event.target.value);
  };

  const handleInputlastName = (event) => {
    setLastname(event.target.value);
  };

  const handleInputMobilenumber = (event) => {
    let value = event.target.value;
    if (isNaN(value) || value.length > 10) {
      event.target.value = mobileNumber;
    } else {
      setMobilenumber(event.target.value);
    }
  };

  const handleInputMessage = (event) => {
    setMessage(event.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (loader === true) {
      return;
    }
    setLoader(true);

    // if (email === "") {
    //   alert("email blank");

    //   return;
    // }
    // if (firstName === "") {
    //   alert("first nname blank");
    //   return;
    // }
    // if (lastName === "") {
    //   alert("last nameblank");
    //   return;
    // }
    // if (message === "") {
    //   alert("message blank");
    //   return;
    // }
    // if (mobileNumber === "") {
    //   alert("number blank");
    //   return;
    // }
    // const form = e.currentTarget;
    // if (form.checkValidity() === false) {
    //   e.preventDefault();
    //   e.stopPropagation();
    // }

    const requestData = {
      firstname: firstName,
      lastname: lastName,
      email: email,
      message: message,
      subject: "Website Contact",
      contact: mobileNumber,
    };

    const formData = new FormData();

    for (var key in requestData) {
      formData.append(key, requestData[key]);
    }
    console.log("formData", formData);

    // const requestJson = JSON.stringify(requestData);
    try {
      const response = await axios.post(
        "https://redefiningsolar.com.au/send_email.php",
        formData
      );
      setLoader(false);
      setShow(true);
      setValidated(true);
      const responseText = await response.text();
      console.log(responseText);
    } catch (ex) {
      setLoader(false);
      console.log("POST error!");
    }
  };

  // function refreshPage() {
  //   window.location.reload(false);
  // }

  return (
    <div>
      <div className="ContactBanner">
        <Container>
          <Row>
            <Col lg={12} md={12} xl={12} sm={12} xs={12}>
              <div className="ContactBanner-left">
                <h2>Contact</h2>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="ContactForm">
        <Container>
          <Form
            ref={form}
            // noValidate
            // validated={validated}
            onSubmit={handleSubmit}
          >
            <Form.Group className="mb-3">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="First Name"
                name="firstName"
                value={firstName}
                onChange={handleInputfirstName}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Last Name"
                name="lastName"
                value={lastName}
                onChange={handleInputlastName}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Contact Number</Form.Label>
              <Form.Control
                type="text"
                placeholder="Contact Number"
                name="number"
                value={mobileNumber}
                onChange={handleInputMobilenumber}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                name="email"
                value={email}
                onChange={handleInputEmail}
                required
                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
              />
              <Form.Control.Feedback type="invalid">
                "Enter Valid Email"
              </Form.Control.Feedback>
              <Form.Text className="text-muted">
                We'll never share your email with anyone else.
              </Form.Text>
            </Form.Group>

            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Message</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                name="message"
                value={message}
                onChange={handleInputMessage}
              />
            </Form.Group>

            <Button
              // onClick={handleSubmit}
              variant="primary"
              type="submit"
              className="my-bttn"
              disabled={loader}
            >
              Submit
              {loader && (
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              )}
            </Button>
          </Form>

          <div className="googleMap">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3139.412592669824!2d145.19122041526126!3d-38.1073345797003!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad60c4528900f51%3A0xa8f14fd0c95a551e!2s32%20Herrington%20Ave%2C%20Carrum%20Downs%20VIC%203201%2C%20Australia!5e0!3m2!1sen!2sin!4v1672902396361!5m2!1sen!2sin"
              width="100%"
              height="450"
              title="map"
            ></iframe>
          </div>
        </Container>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Form Submitted</Modal.Title>
          </Modal.Header>
          <Modal.Body>Form submitted successfully!</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}

export default ContactBanner;
