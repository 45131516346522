import { React, useEffect } from "react";
import Container from "react-bootstrap/Container";
import { Link } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import "./Footer.css";
import logo from "../../assets/img/logo2.png";
import Projects from "./../Projects/Projects";

function Footer() {
  const navigate = useNavigate();
  const formEnquiry = () => {
    navigate("contact");
    window.scrollTo(0, 0);
  };

  return (
    <div className="apslute-footers">
      <Container>
        <Row>
          <Col>
            <div className="apslute-footer">
              <div className="text">
                <h2>Generate electricity in easy manner </h2>
              </div>

              <div className="button">
                <Button
                  onClick={formEnquiry}
                  variant="danger"
                  className="my-bttn"
                >
                  Enquiry Now
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <div className="Footer">
        <Container>
          <Row className="Footer-containt">
            <Col lg={3} md={3} xl={3} sm={6} xs={6}>
              <div className="Footer-1-sec">
                <div className="Footer-logo">
                  <img src={logo} className="App-logo" alt="Redefining Solar" />
                </div>
                <div className="Footer-text">
                  <p>
                    We Offer a Wide Range of Quality Solar Panel Installation
                    Services.
                  </p>

                  <p>
                    Currently we provide services in Melbourne, New South Wales
                    & Queensland.
                  </p>
                </div>
              </div>
            </Col>
            <Col lg={3} md={3} xl={3} sm={6} xs={6}>
              <div className="Footer-2-sec">
                <h5>Explore</h5>
                <div className="Footer-text">
                  <p>
                    <Nav.Link as={Link} to={"/home"}>
                      Home
                    </Nav.Link>
                  </p>
                  <p>
                    <Nav.Link as={Link} to={"/about"}>
                      About
                    </Nav.Link>
                  </p>
                  <p>
                    <Nav.Link as={Link} to={"/products"}>
                      Products
                    </Nav.Link>
                  </p>
                  <p>
                    <Nav.Link as={Link} to={"/contact"}>
                      Contact Us
                    </Nav.Link>
                  </p>
                  <p>
                    <Nav.Link as={Link} to={"/privacy"}>
                      Privacy & Conditions
                    </Nav.Link>
                  </p>
                </div>
              </div>
            </Col>

            <Col lg={3} md={3} xl={3} sm={6} xs={6}>
              <div className="Footer-3-sec">
                <h5>Services</h5>
                <div className="Footer-text">
                  <p>
                    <a href="">Solar Power</a>
                  </p>
                  <p>
                    <a href="">Solar Hot Water</a>
                  </p>
                  <p>
                    <a href="">Solar Rebates & Subsidies</a>
                  </p>
                  <p>
                    <a href=""></a>
                  </p>
                </div>
              </div>
            </Col>

            <Col lg={3} md={3} xl={3} sm={6} xs={6}>
              <div className="Footer-4-sec">
                <h5>Contact</h5>
                <div className="Footer-text">
                  <p>
                    <a href="mailto:redefiningsolar@gmail.com">Send email</a>
                  </p>
                  <p>
                    <a href="tel:+61-425 400 755">1300309956</a>
                  </p>

                  <p>32 Herrington Avenue, Carrum Downs, VIC- 3201</p>

                  <a
                    href="https://twitter.com/RedefiningSolar"
                    target="_blank "
                    className="social"
                  >
                    <svg
                      fill="#000000"
                      viewBox="0 0 30 30"
                      width="30px"
                      height="30px"
                    >
                      <path d="M28,6.937c-0.957,0.425-1.985,0.711-3.064,0.84c1.102-0.66,1.947-1.705,2.345-2.951c-1.03,0.611-2.172,1.055-3.388,1.295 c-0.973-1.037-2.359-1.685-3.893-1.685c-2.946,0-5.334,2.389-5.334,5.334c0,0.418,0.048,0.826,0.138,1.215 c-4.433-0.222-8.363-2.346-10.995-5.574C3.351,6.199,3.088,7.115,3.088,8.094c0,1.85,0.941,3.483,2.372,4.439 c-0.874-0.028-1.697-0.268-2.416-0.667c0,0.023,0,0.044,0,0.067c0,2.585,1.838,4.741,4.279,5.23 c-0.447,0.122-0.919,0.187-1.406,0.187c-0.343,0-0.678-0.034-1.003-0.095c0.679,2.119,2.649,3.662,4.983,3.705 c-1.825,1.431-4.125,2.284-6.625,2.284c-0.43,0-0.855-0.025-1.273-0.075c2.361,1.513,5.164,2.396,8.177,2.396 c9.812,0,15.176-8.128,15.176-15.177c0-0.231-0.005-0.461-0.015-0.69C26.38,8.945,27.285,8.006,28,6.937z" />
                    </svg>
                  </a>
                  <a
                    href="https://www.facebook.com/profile.php?id=100089453670600&mibextid=ZbWKwL"
                    target="_blank "
                    className="social"
                  >
                    <svg
                      fill="#000000"
                      viewBox="0 0 50 50"
                      width="30px"
                      height="30px"
                    >
                      <path d="M32,11h5c0.552,0,1-0.448,1-1V3.263c0-0.524-0.403-0.96-0.925-0.997C35.484,2.153,32.376,2,30.141,2C24,2,20,5.68,20,12.368 V19h-7c-0.552,0-1,0.448-1,1v7c0,0.552,0.448,1,1,1h7v19c0,0.552,0.448,1,1,1h7c0.552,0,1-0.448,1-1V28h7.222 c0.51,0,0.938-0.383,0.994-0.89l0.778-7C38.06,19.518,37.596,19,37,19h-8v-5C29,12.343,30.343,11,32,11z" />
                    </svg>
                  </a>
                  <a
                    href="https://instagram.com/redefiningsolar?igshid=YmMyMTA2M2Y="
                    target="_blank "
                    className="social"
                  >
                    <svg
                      fill="#000000"
                      viewBox="0 0 24 24"
                      width="24px"
                      height="24px"
                    >
                      <path d="M 8 3 C 5.243 3 3 5.243 3 8 L 3 16 C 3 18.757 5.243 21 8 21 L 16 21 C 18.757 21 21 18.757 21 16 L 21 8 C 21 5.243 18.757 3 16 3 L 8 3 z M 8 5 L 16 5 C 17.654 5 19 6.346 19 8 L 19 16 C 19 17.654 17.654 19 16 19 L 8 19 C 6.346 19 5 17.654 5 16 L 5 8 C 5 6.346 6.346 5 8 5 z M 17 6 A 1 1 0 0 0 16 7 A 1 1 0 0 0 17 8 A 1 1 0 0 0 18 7 A 1 1 0 0 0 17 6 z M 12 7 C 9.243 7 7 9.243 7 12 C 7 14.757 9.243 17 12 17 C 14.757 17 17 14.757 17 12 C 17 9.243 14.757 7 12 7 z M 12 9 C 13.654 9 15 10.346 15 12 C 15 13.654 13.654 15 12 15 C 10.346 15 9 13.654 9 12 C 9 10.346 10.346 9 12 9 z" />
                    </svg>
                  </a>

                  <a
                    href="https://www.linkedin.com/in/redefining-solar-039884260"
                    target="_blank "
                    className="social"
                  >
                    <svg viewBox="0 0 30 30" width="30px" height="30px">
                      <path d="M9,25H4V10h5V25z M6.501,8C5.118,8,4,6.879,4,5.499S5.12,3,6.501,3C7.879,3,9,4.121,9,5.499C9,6.879,7.879,8,6.501,8z M27,25h-4.807v-7.3c0-1.741-0.033-3.98-2.499-3.98c-2.503,0-2.888,1.896-2.888,3.854V25H12V9.989h4.614v2.051h0.065 c0.642-1.18,2.211-2.424,4.551-2.424c4.87,0,5.77,3.109,5.77,7.151C27,16.767,27,25,27,25z" />
                    </svg>
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default Footer;
