import React from "react";
import ServicesBanner from "./ServicesBanner/ServicesBanner";

function Services() {
  return (
    <div>
      <ServicesBanner />
    </div>
  );
}

export default Services;
