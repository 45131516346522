import React from "react";
import { useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./Products.css";
import Products1 from "../../../assets/img/products1.jpg";
import inverters from "../../../assets/img/INVERTERS.png";
import panel from "../../../assets/img/panel.jpg";
import mainProduct from "../../../assets/img/men-prodcts.jpg";
import img1 from "../../../assets/img/zinko.jpeg";
import img2 from "../../../assets/img/innverter.jpeg";
import img3 from "../../../assets/img/Risen.png";



function Products() {

  const location = useLocation();
  const [hideSection, setHideSection] = useState(false);
console.log("location",location)
  useEffect(() => {
    // Check if the URL contains a certain string
    if (location.pathname.includes('/home')) {
      setHideSection(true);
    } else {
      setHideSection(false);
    }
  }, [location]);

  return (

    
    <div className="Products">





      <Container>
        <Row>
          <Col>
            <div className="Products-Heading text-center">
              <h2 className="line">Our Products</h2>
            </div>
          </Col>
        </Row>


        {!hideSection && (
        <section>
          {/* <Your section content> */}
          <Row  className="add-products">
          <Col lg={4} md={4} xl={4} sm={12} xs={12}>
            <div className="text-center product-imgs product-imgs-1">
            <img src={img1} alt="Redefining Solar" />
            <p>16 JINKO N TYPE 415 watts Solar</p>
            </div>
          </Col>
          <Col lg={4} md={4} xl={4} sm={12} xs={12}>
            <div className=" text-center product-imgs">
            <img src={img2} alt="Redefining Solar" />
            <p>panels with 5kw Sungrow single phase Inverter</p>
            </div>
          </Col>
          <Col lg={4} md={4} xl={4} sm={12} xs={12}>
            <div className=" text-center product-imgs">
            <img src={img3} alt="Redefining Solar" />
            <p>16 RISEN 415 watts Solar panels</p>
            </div>
          </Col>
        </Row>

        </section>
      )}



        <Row  className="align-items-center ">
          <Col lg={6} md={12} xl={6} sm={12} xs={12}>
            <div className="Products-left">
              <h2 className="mb-5"> Complete Solar Value Chain</h2>
              <p>
                <b>Solar panels</b> are those devices which are used to absorb
                the sun's rays and convert them into electricity or heat.
                Description: A solar panel is actually a collection of solar (or
                photovoltaic) cells, which can be used to generate electricity
                through photovoltaic effect.
              </p>
              <p>
                <b>solar battery</b> is a device that you can add to your solar
                power system to store the excess electricity generated by your
                solar panels. You can then use that stored energy to power your
                home at times when your solar panels don't generate enough
                electricity, including nights, cloudy days, and during power
                outages.
              </p>

              <p>
                <b> Solar inverter </b> is one of the most important pieces of
                equipment in a solar energy system. It's a device that converts
                direct current (DC) electricity, which is what a solar panel
                generates, to alternating current (AC) electricity, which the
                electrical grid uses.
              </p>
              <h5>
                We provide services in Melbourne, New South Wales & Queensland.
              </h5>
            </div>
          </Col>
          <Col lg={6} md={12} xl={6} sm={12} xs={12} className=" text-center">
            <div className="Products-right">
              <div className="Products-right-box">
                <div className="Products-right-box-img">
                  <img src={mainProduct} alt="Redefining Solar" />
                </div>
                <div className="Products-right1">
                  <img src={inverters} alt="Redefining Solar" />
                </div>
                <div className="Products-right2">
                  <img src={panel} alt="Redefining Solar" />
                </div>

                {/* <div className="Products-right3">
                 <img src={Products1} alt="Redefining Solar" />
                </div>*/}

                <div className="Products-right4">
                  <img src={Products1} alt="Redefining Solar" />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Products;
