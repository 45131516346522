import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import "./AboutBanner.css";
function AboutBanner() {
  const navigate = useNavigate();
  const formEnquiry = () => {
    navigate("/GetQuote");
  };
  return (
    <div>
      <div className="AboutBanner">
        <Container>
          <Row>
            <Col lg={12} md={12} xl={12} sm={12} xs={12}>
              <div className="AboutBanner-left">
                <h2>About</h2>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="AboutBannerAppsulate">
        <Container>
          <div className="AboutBannerAppsulateText">
            <h3>A simple, affordable way to control your energy bill </h3>
            <Button onClick={formEnquiry} variant="danger" className="my-bttn">
              Get a Quote
            </Button>
          </div>
        </Container>
      </div>
    </div>
  );
}

export default AboutBanner;
